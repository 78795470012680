import { useEffect, useReducer, useRef, useState } from "react";
import * as Colyseus from "colyseus.js";
import { useParams } from "react-router-dom";
import "./Game.css";
import {
	BottomNavigation,
	BottomNavigationAction,
	Button,
	Paper,
	Typography,
} from "@mui/material";
import ReactPlayer from "react-player";
import WarningIcon from "@mui/icons-material/Warning";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { declOfNum } from "../../helpers/misc";

function Game() {
	let { gameID, placeID } = useParams();
	const [client, setClient] = useState(null);
	const [room, setRoom] = useState(null);
	const [state, setState] = useState(null);
	const [gamer, setGamer] = useState(null);
	const [player, setPlayer] = useState(null);
	const [error, setError] = useState("");
	const [viewer, setViewer] = useState(true);
	const [page, setPage] = useState(0);
	const [modal, setModal] = useState(null);
	const [modalFull, setModalFull] = useState(false);
	const forceUpdate = useReducer(() => ({}))[1];
	const videoplayer = useRef(null);

	const CARDS_HEADERS = [
		"ПРОФЕССИЯ",
		"БИОЛОГИЯ",
		"ЗДОРОВЬЕ",
		"ФАКТЫ",
		"БАГАЖ",
		"ХОББИ",
	];

	useEffect(() => {
		setClient(new Colyseus.Client("wss://bunker.ugscompany.ru:2567"));
	}, []);

	useEffect(() => {
		const joinToGame = async () => {
			try {
				let reconnected = false;
				let newRoom;

				let existData = localStorage.getItem("bunkerData");
				if (existData) {
					try {
						let existJson = JSON.parse(existData);
						if (existJson?.roomId === gameID && existJson?.sessionId) {
							newRoom = await client.reconnect(gameID, existJson.sessionId);
							reconnected = true;
						}
					} catch (error) {
						console.log(error);
					}
				}

				if (!reconnected)
					newRoom = await client.joinById(gameID, {
						place: parseInt(placeID),
						/* options */
					});
				console.log("joined successfully", newRoom);
				window.gRoom = newRoom;

				localStorage.setItem(
					"bunkerData",
					JSON.stringify({
						roomId: newRoom.id,
						sessionId: newRoom.sessionId,
					})
				);

				setRoom(newRoom);
				setError("");
			} catch (e) {
				console.error("join error", e);
				setError("Ошибка при входе в игру");
			}
		};
		if (client) joinToGame();
	}, [client]);

	useEffect(() => {
		const setupListners = () => {
			room.onStateChange((lState) => {
				console.log(room.name, "has new state:", lState);
				setState(lState);
				window.gState = lState;
				if (lState.players.has(room.sessionId)) {
					let gamerData = lState.players.get(room.sessionId);
					let playerData = lState.virtualPlayers[gamerData.num - 1];
					setGamer(gamerData);
					setPlayer(playerData);
					setViewer(false);
					if (playerData.waitForOpen) setPage(2);
				} else {
					if (!viewer) setPage(0);
					setViewer(true);
				}
				forceUpdate();
			});

			room.onMessage("reset_video", (message) => {
				console.log("message 'reset_video' received from server", message);
				videoplayer?.current?.seekTo(0);
			});

			room.onMessage("hide_openned_card", (message) => {
				console.log(
					"message 'hide_openned_card' received from server",
					message
				);
				setModal(null);
			});

			room.onMessage("hide_openned_card_full", (message) => {
				console.log(
					"message 'hide_openned_card_full' received from server",
					message
				);
				// if (modalFull) {
				setModalFull(false);
				forceUpdate();
				// setModal({
				// 	full: false,
				// 	fullCard: modal.fullCard,
				// 	card: modal.card,
				// 	num: modal.num,
				// });
				// forceUpdate();
				// }
			});

			room.onMessage("show_openned_card", (message) => {
				console.log(
					"message 'show_openned_card' received from server",
					message
				);
				setModal({
					fullCard: message.card,
					card: message.card === 6 ? 3 : message.card,
					num: message.num,
					// full: message.card === 6,
				});
				setModalFull(message.card === 6);
				forceUpdate();
			});
		};
		if (room) setupListners();
	}, [room]);

	const handleOpenCard = (card) => {
		room.send("VitrualPlayer_card_player", { card });
	};

	const handleVote = (num) => {
		room.send("VitrualPlayer_vote", { num });
	};

	const handleSubvote = (num) => {
		room.send("VitrualPlayer_subvote", { num });
	};

	let pageContent = <></>;
	switch (page) {
		case 0:
			pageContent = (
				<div style={{ marginTop: "35vh", transform: "translateY(-50%)" }}>
					{/* <ReactPlayer
						className="Game-minivideo"
						url={state?.gameData?.catastrophe?.video}
						muted={true}
						playing={true}
						loop={true}
						// controls={true}
						width="70vw"
						height="auto"
					/> */}
					<Typography align="center" variant="h4">
						{state?.gameData?.catastrophe?.name}
					</Typography>
					<Typography
						style={{ maxWidth: 950, margin: "auto" }}
						align="center"
						variant="h6"
					>
						{state?.gameData?.catastrophe?.description}
					</Typography>
				</div>
			);
			break;

		case 1:
			pageContent = (
				<div style={{ marginTop: "35vh", transform: "translateY(-50%)" }}>
					<Typography align="center" variant="h4">
						Бункер
					</Typography>
					<Typography
						style={{ maxWidth: 950, margin: "auto" }}
						align="center"
						variant="h6"
						dangerouslySetInnerHTML={{
							__html: state?.gameData?.bunker?.description,
						}}
					/>
				</div>
			);
			break;

		case 2:
			pageContent = (
				<div className="Game-mycards">
					{player?.cards.map((card, index) => {
						if (index < 6) {
							if (index === 3) {
								let secondfact = player.cards[6];
								return (
									<div className="Game-block">
										<Paper
											className="Game-innerblock"
											variant="outlined"
											style={{
												background:
													card.openned || secondfact.openned ? "#66bb6a7a" : "",
											}}
											onClick={
												player.waitForOpen && !card.openned
													? () => handleOpenCard(index)
													: () => {}
											}
										>
											<Typography align="center" variant="h5">
												{CARDS_HEADERS[index]}
											</Typography>
											<Typography align="center" variant="h4">
												{card.text}
												{secondfact.openned ? ` / ${secondfact.text}` : ""}
											</Typography>
										</Paper>
										{player.waitForOpen && !card.openned && (
											<Button
												variant="outlined"
												color="success"
												onClick={() => handleOpenCard(index)}
											>
												Открыть
											</Button>
										)}
									</div>
								);
							} else
								return (
									<div className="Game-block">
										<Paper
											className="Game-innerblock"
											variant="outlined"
											style={{
												background: card.openned ? "#66bb6a7a" : "",
											}}
											onClick={
												player.waitForOpen && !card.openned
													? () => handleOpenCard(index)
													: () => {}
											}
										>
											<Typography align="center" variant="h5">
												{CARDS_HEADERS[index]}
											</Typography>
											<Typography align="center" variant="h4">
												{card.text}
											</Typography>
										</Paper>
										{player.waitForOpen && !card.openned && (
											<Button
												variant="outlined"
												color="success"
												onClick={() => handleOpenCard(index)}
											>
												Открыть
											</Button>
										)}
									</div>
								);
						} else return <></>;
					})}
				</div>
			);
			break;

		case 3:
			pageContent = (
				<div className="Game-allcards">
					{state?.players &&
						Object.entries(state.players.toJSON())
							.sort((row1, row2) => row1[1].num - row2[1].num)
							.map((row) => {
								let pData = row[1];
								let vID = pData.num - 1;
								let vData = state.virtualPlayers[vID];
								return (
									<Paper
										className="Game-block"
										variant="outlined"
										style={{ color: vData.alive ? "" : "#6e6e6e" }}
									>
										<Typography align="center" variant="h5" gutterBottom>
											ИГРОК #{pData.num}
										</Typography>
										{vData.cards.map((card, index) => {
											if (index < 6) {
												if (index === 3) {
													let secondfact = vData.cards[6];
													return (
														<Typography variant="body2">
															* {card.openned ? card.text : "???"}
															{secondfact.openned
																? ` / ${secondfact.text}`
																: ""}
														</Typography>
													);
												} else
													return (
														<Typography variant="body2">
															* {card.openned ? card.text : "???"}
														</Typography>
													);
											} else return <></>;
										})}
									</Paper>
								);
							})}
				</div>
			);
			break;

		default:
			break;
	}

	return (
		<div
			className="Game-main"
			style={{
				background: ["rules", "catastrophe", "final"].includes(
					state?.gameData?.view
				)
					? "#000"
					: "inherit",
			}}
		>
			{state?.timerStarted && (
				<Typography
					variant="h3"
					style={{
						position: "absolute",
						top: "0",
						right: "8px",
					}}
				>
					{state?.seconds}
				</Typography>
			)}
			{modal ? (
				<>
					<Typography gutterBottom align="center" variant="h3">
						ИГРОК #{modal.num + 1} ОТКРЫЛ КАРТУ
					</Typography>
				</>
			) : viewer ? (
				<Typography gutterBottom align="center" variant="h3">
					ЗРИТЕЛЬ
				</Typography>
			) : (
				<Typography gutterBottom align="center" variant="h3">
					ИГРОК #{gamer?.num}
				</Typography>
			)}
			{error && (
				<Typography gutterBottom align="center" variant="subtitle2" color="red">
					{error}
				</Typography>
			)}
			{state?.gameData?.view === "logo" && (
				<div>
					<img className="Game-img" alt="" src="/images/corner.png" />
					<div style={{ marginTop: "45vh", transform: "translateY(-50%)" }}>
						<Typography align="center" variant="h1">
							ТВОЙ ШАНС
						</Typography>
					</div>
				</div>
			)}
			{state?.gameData?.view === "rules" && (
				<ReactPlayer
					ref={videoplayer}
					className="Game-video"
					url={state?.videoControl?.video}
					playing={state?.videoControl?.play}
					loop={false}
					muted={true}
					width="100vw"
					height="auto"
				/>
			)}
			{state?.gameData?.view === "catastrophe" && (
				<ReactPlayer
					ref={videoplayer}
					className="Game-video"
					url={state?.videoControl?.video}
					playing={state?.videoControl?.play}
					loop={false}
					muted={true}
					width="100vw"
					height="auto"
				/>
			)}
			{state?.gameData?.view === "game" &&
				((modal &&
					((modalFull && (
						<div style={{ marginTop: "45vh", transform: "translateY(-50%)" }}>
							<Typography align="center" variant="h1">
								{state?.virtualPlayers[modal.num]?.cards[modal.fullCard]
									?.openned
									? state?.virtualPlayers[modal.num]?.cards[modal.fullCard]
											?.text
									: "???"}
							</Typography>
						</div>
					)) || (
						<div className="Game-othercards">
							{state?.virtualPlayers[modal.num]?.cards.map((card, index) => {
								if (index < 6) {
									if (index === 3) {
										let secondfact = state.virtualPlayers[modal.num].cards[6];
										return (
											<Paper
												className="Game-block"
												variant="outlined"
												style={{
													background:
														index === modal.card
															? "#005aff78"
															: card.openned || secondfact.openned
															? "#66bb6a7a"
															: "",
												}}
											>
												<Typography align="center" variant="h5">
													{CARDS_HEADERS[index]}
												</Typography>
												<Typography align="center" variant="h4">
													{card.openned ? card.text : "???"}
													{secondfact.openned ? ` / ${secondfact.text}` : ""}
												</Typography>
											</Paper>
										);
									} else
										return (
											<Paper
												className="Game-block"
												variant="outlined"
												style={{
													background:
														index === modal.card
															? "#005aff78"
															: card.openned
															? "#66bb6a7a"
															: "",
												}}
											>
												<Typography align="center" variant="h5">
													{CARDS_HEADERS[index]}
												</Typography>
												<Typography align="center" variant="h4">
													{card.openned ? card.text : "???"}
												</Typography>
											</Paper>
										);
								} else return <></>;
							})}
						</div>
					))) || (
					<>
						{pageContent}
						<Paper
							sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
							elevation={3}
						>
							{viewer ? (
								<BottomNavigation
									showLabels
									value={page}
									onChange={(event, newValue) => {
										setPage(newValue);
									}}
								>
									<BottomNavigationAction
										label="Катастрофа"
										icon={<WarningIcon />}
									/>
									<BottomNavigationAction
										label="Бункер"
										icon={<HomeWorkIcon />}
									/>
								</BottomNavigation>
							) : (
								<BottomNavigation
									showLabels
									value={page}
									onChange={(event, newValue) => {
										setPage(newValue);
									}}
								>
									<BottomNavigationAction
										label="Катастрофа"
										icon={<WarningIcon />}
									/>
									<BottomNavigationAction
										label="Бункер"
										icon={<HomeWorkIcon />}
									/>
									<BottomNavigationAction
										label="Мои карты"
										icon={<ViewCarouselIcon />}
									/>
									<BottomNavigationAction
										label="Сводная таблица"
										icon={<DashboardIcon />}
									/>
								</BottomNavigation>
							)}
						</Paper>
					</>
				))}
			{state?.gameData?.view === "vote" && (
				<div className="Game-votecards">
					{state?.players &&
						Object.entries(state.players.toJSON())
							.sort((row1, row2) => row1[1].num - row2[1].num)
							.map((row) => {
								let pData = row[1];
								let vID = pData.num - 1;
								let vData = state.virtualPlayers[vID];
								let votesCount = 0;
								for (let vp of state.virtualPlayers)
									if (vp.vote === pData.num) votesCount++;
								return (
									<div className="Game-block">
										<Paper
											className="Game-innerblock"
											variant="outlined"
											style={{ color: vData.alive ? "" : "#6e6e6e" }}
											onClick={
												vData.alive && !viewer && player?.waitForVote
													? () => handleVote(pData.num)
													: () => {}
											}
										>
											<Typography align="center" variant="h5" gutterBottom>
												ИГРОК #{pData.num}
											</Typography>
											{vData.cards.map((card, index) => {
												if (index < 6) {
													if (index === 3) {
														let secondfact = vData.cards[6];
														return (
															<Typography variant="body2">
																* {card.openned ? card.text : "???"}
																{secondfact.openned
																	? ` / ${secondfact.text}`
																	: ""}
															</Typography>
														);
													} else
														return (
															<Typography variant="body2">
																* {card.openned ? card.text : "???"}
															</Typography>
														);
												} else return <></>;
											})}
										</Paper>
										{vData.alive &&
											((!viewer && player?.waitForVote && (
												<Button
													variant="outlined"
													color="info"
													onClick={() => handleVote(pData.num)}
												>
													Выдвинуть
												</Button>
											)) ||
												(votesCount > 0 && (
													<Button variant="outlined" color="error">
														ВЫДВИНУТ
													</Button>
												)))}
									</div>
								);
							})}
				</div>
			)}
			{state?.gameData?.view === "subvote" && (
				<div className="Game-votecards">
					{state?.players &&
						Object.entries(state.players.toJSON())
							.sort((row1, row2) => row1[1].num - row2[1].num)
							.map((row) => {
								let pData = row[1];
								let vID = pData.num - 1;
								let vData = state.virtualPlayers[vID];
								let votesCount = 0;
								for (let vp of state.virtualPlayers)
									if (vp.vote === pData.num) votesCount++;
								return (
									<div className="Game-block">
										<Paper
											className="Game-innerblock"
											variant="outlined"
											style={{ color: vData.alive ? "" : "#6e6e6e" }}
											onClick={
												vData.voted &&
												vData.alive &&
												!viewer &&
												player?.waitForVote
													? () => handleSubvote(pData.num)
													: () => {}
											}
										>
											<Typography align="center" variant="h5" gutterBottom>
												ИГРОК #{pData.num}
											</Typography>
											{vData.cards.map((card, index) => {
												if (index < 6) {
													if (index === 3) {
														let secondfact = vData.cards[6];
														return (
															<Typography variant="body2">
																* {card.openned ? card.text : "???"}
																{secondfact.openned
																	? ` / ${secondfact.text}`
																	: ""}
															</Typography>
														);
													} else
														return (
															<Typography variant="body2">
																* {card.openned ? card.text : "???"}
															</Typography>
														);
												} else return <></>;
											})}
										</Paper>
										{vData.voted &&
											vData.alive &&
											((!viewer && player?.waitForVote && (
												<Button
													variant="outlined"
													color="error"
													onClick={() => handleSubvote(pData.num)}
												>
													Выгнать
												</Button>
											)) || (
												<Button variant="outlined" color="info">
													{votesCount === 0
														? "НЕТ ГОЛОСОВ"
														: `${votesCount} ${declOfNum(votesCount, [
																"ГОЛОС",
																"ГОЛОСА",
																"ГОЛОСОВ",
														  ])}`}
												</Button>
											))}
									</div>
								);
							})}
				</div>
			)}
			{state?.gameData?.view === "final" && (
				<ReactPlayer
					ref={videoplayer}
					className="Game-video"
					url={state?.videoControl?.video}
					playing={state?.videoControl?.play}
					loop={false}
					muted={true}
					width="100vw"
					height="auto"
				/>
			)}
		</div>
	);
}

export default Game;
