import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Home.css";

function Home() {
	let history = useHistory();

	const [roomid, setRoomid] = useState("");
	const [place, setPlace] = useState("");

	function handleClick() {
		if (roomid && place) history.push(`/game/${roomid}/${place}`);
	}

	return (
		<div className="Home-div">
			<Typography className="Home-header" variant="h1"></Typography>
			<TextField
				id="outlined-basic"
				label="ID/Место"
				variant="outlined"
				value={roomid || place ? `${roomid}/${place}` : ""}
				onChange={(e) => {
					let input = e.target.value.split("/");
					setRoomid(input[0]);
					if (input.length > 1) setPlace(input[1]);
				}}
			/>
			<Button className="Home-button" variant="outlined" onClick={handleClick}>
				ВОЙТИ
			</Button>
		</div>
	);
}

export default Home;
