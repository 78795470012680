import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Game from "../Game/Game";
import Home from "../Home/Home";
import Admin from "../Admin/Admin";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import View from "../View/View";

function App() {
	const darkTheme = createTheme({
		palette: {
			mode: "dark",
		},
	});

	return (
		<ThemeProvider theme={darkTheme}>
			<CssBaseline />
			<Router>
				<Switch>
					<Route path="/admin/:gameID">
						<Admin />
					</Route>
					<Route path="/admin">
						<Admin />
					</Route>
					<Route path="/game/:gameID/:placeID">
						<Game />
					</Route>
					<Route path="/view/:gameID">
						<View />
					</Route>
					<Route path="/">
						<Home />
					</Route>
				</Switch>
			</Router>
		</ThemeProvider>
	);
}

export default App;
