import "./Admin.css";
import * as Colyseus from "colyseus.js";
import { useEffect, useReducer, useState } from "react";
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	InputLabel,
	MenuItem,
	Paper,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useHistory, useParams } from "react-router-dom";

function Admin() {
	let { gameID } = useParams();

	const [client, setClient] = useState(null);
	const [room, setRoom] = useState(null);
	const [state, setState] = useState(null);
	const [error, setError] = useState("");
	const [timer, setTimer] = useState(30);
	const [timerOpen, setTimerOpen] = useState(false);
	const [final, setFinal] = useState("win");
	const [finalOpen, setFinalOpen] = useState(false);
	const [catastropheOpen, setCatastropheOpen] = useState(false);
	const [bunkerOpen, setBunkerOpen] = useState(false);
	const forceUpdate = useReducer(() => ({}))[1];

	let history = useHistory();

	useEffect(() => {
		setClient(new Colyseus.Client("wss://bunker.ugscompany.ru:2567"));
	}, []);

	useEffect(() => {
		const createRoom = async () => {
			try {
				let newRoom;
				if (!gameID) {
					newRoom = await client.create("bunker", {
						admin: true,
					});
					history.push(`/admin/${newRoom.id}`);
				} else
					newRoom = await client.joinById(gameID, {
						admin: true,
					});
				setRoom(newRoom);
				setError("");
			} catch (e) {
				setError("Ошибка при создании комнаты");
			}
		};
		if (client) createRoom();
	}, [client]);

	useEffect(() => {
		const setupListners = () => {
			room.onStateChange((lState) => {
				console.log(room.name, "has new state:", lState);
				setState(lState);
				forceUpdate();
			});
		};
		if (room) setupListners();
	}, [room]);

	const handleViewChange = (event) => {
		room.send("GameData_view", { view: event.target.value });
	};

	const handleAutoplayChange = (event) => {
		room.send("VideoControl_autoplay", { autoplay: event.target.checked });
	};

	const handlePlayChange = (play) => {
		room.send("VideoControl_play", { play });
	};

	const handleCatastropheChange = () => {
		room.send("GameData_catastrophe", {});
	};

	const handleBunkerChange = () => {
		room.send("GameData_bunker", {});
	};

	const handleJoinPlayer = (sessionId) => {
		room.send("Player_join", { sessionId });
	};

	const handleKickPlayer = (sessionId) => {
		room.send("Player_kick", { sessionId });
	};

	const handleKickQueuePlayer = (sessionId) => {
		room.send("QueuePlayer_kick", { sessionId });
	};

	const handleOpenCard = (num, card, value) => {
		room.send("VitrualPlayer_card", { num, card, value });
	};

	const handleWaitCard = (num, value) => {
		room.send("VitrualPlayer_wait", { num, value });
	};

	const handleKillPlayer = (num, value) => {
		room.send("VitrualPlayer_alive", { num, value });
	};

	const handleVotedPlayer = (num, value) => {
		room.send("VitrualPlayer_voted", { num, value });
	};

	const handleNewCards = () => {
		room.send("GameData_reset", {});
	};

	const handleResetVideo = () => {
		room.send("VideoControl_reset", {});
	};

	const handleHideModal = () => {
		room.send("Modal_hide", {});
	};

	const handleHideFullModal = () => {
		room.send("Modal_full_hide", {});
	};

	const handleMoveTop = (sessionId) => {
		room.send("Player_movetop", { sessionId });
	};

	const handleMoveBot = (sessionId) => {
		room.send("Player_movebot", { sessionId });
	};

	const handleCloseCatastrophe = () => {
		setCatastropheOpen(false);
	};

	const handleOpenCatastrophe = () => {
		setCatastropheOpen(true);
	};

	const handleCloseBunker = () => {
		setBunkerOpen(false);
	};

	const handleOpenBunker = () => {
		setBunkerOpen(true);
	};

	const handleCloseFinal = () => {
		setFinalOpen(false);
	};

	const handleOpenFinal = () => {
		setFinalOpen(true);
	};

	const handleFinalChange = (event) => {
		setFinal(event.target.value);
	};

	const handleFinalSelect = () => {
		room.send("GameData_final", { final });
		handleCloseFinal();
	};

	const handleCloseTimer = () => {
		setTimerOpen(false);
	};

	const handleOpenTimer = () => {
		setTimerOpen(true);
	};

	const handleTimerChange = (event) => {
		setTimer(parseInt(event.target.value));
	};

	const handleTimerSelect = () => {
		room.send("GameData_timer", { timer });
		handleCloseTimer();
	};

	return (
		<div>
			<Dialog
				open={catastropheOpen}
				onClose={handleCloseCatastrophe}
				className="Admin-dialog"
			>
				<DialogTitle>{state?.gameData?.catastrophe?.name}</DialogTitle>
				<DialogContent>
					<DialogContentText gutterBottom>
						{state?.gameData?.catastrophe?.description}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseCatastrophe}>Закрыть</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={bunkerOpen}
				onClose={handleCloseBunker}
				className="Admin-dialog"
			>
				<DialogTitle>Бункер</DialogTitle>
				<DialogContent>
					<DialogContentText
						gutterBottom
						dangerouslySetInnerHTML={{
							__html: state?.gameData?.bunker?.description,
						}}
					></DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseBunker}>Закрыть</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={finalOpen}
				onClose={handleCloseFinal}
				className="Admin-dialog"
			>
				<DialogTitle>Показать финал</DialogTitle>
				<DialogContent>
					<DialogContentText gutterBottom>
						Выберите финал для отображения:
					</DialogContentText>
					<FormControl fullWidth>
						<InputLabel id="demo-final-select-label">Финал</InputLabel>
						<Select
							labelId="demo-final-select-label"
							id="demo-final-select"
							value={final}
							label="Финал"
							onChange={handleFinalChange}
						>
							<MenuItem value={"win"}>Победа</MenuItem>
							<MenuItem value={"lose"}>Поражение</MenuItem>
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseFinal}>Отмена</Button>
					<Button onClick={handleFinalSelect}>Показать</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={timerOpen}
				onClose={handleCloseTimer}
				className="Admin-dialog"
			>
				<DialogTitle>Запустить таймер</DialogTitle>
				<DialogContent>
					<DialogContentText gutterBottom>
						Выберите длительность таймера:
					</DialogContentText>
					<FormControl fullWidth>
						<InputLabel id="demo-timer-select-label">Таймер</InputLabel>
						<Select
							labelId="demo-timer-select-label"
							id="demo-timer-select"
							value={timer}
							label="Таймер"
							onChange={handleTimerChange}
						>
							<MenuItem value={30}>30 сек.</MenuItem>
							<MenuItem value={60}>60 сек.</MenuItem>
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseTimer}>Отмена</Button>
					<Button onClick={handleTimerSelect}>Показать</Button>
				</DialogActions>
			</Dialog>

			<Typography align="center" variant="h3">
				БУНКЕР #{room?.id}
			</Typography>
			{state?.timerStarted && (
				<Typography
					variant="h3"
					style={{
						position: "absolute",
						top: "0",
						right: "8px",
					}}
				>
					{state?.seconds}
				</Typography>
			)}
			{error && (
				<Typography align="center" variant="subtitle2" color="red">
					{error}
				</Typography>
			)}
			<div className="Admin-main">
				<Paper className="Admin-block Admin-c1" variant="outlined">
					<Typography variant="subtitle1">Отображение</Typography>
					<FormControl component="fieldset">
						{/* <FormLabel component="legend">Отображение</FormLabel> */}
						<RadioGroup
							aria-label="view"
							name="radio-buttons-group"
							value={state?.gameData?.view || "logo"}
							onChange={handleViewChange}
						>
							<FormControlLabel
								value="logo"
								control={<Radio />}
								label="Заставка"
							/>
							<FormControlLabel
								value="rules"
								control={<Radio />}
								label="Правила"
							/>
							<FormControlLabel
								value="catastrophe"
								control={<Radio />}
								label="Катастрофа"
							/>
							{/* <FormControlLabel
								value="bunker"
								control={<Radio />}
								label="Бункер"
							/> */}
							<FormControlLabel value="game" control={<Radio />} label="Игра" />
							<FormControlLabel
								value="vote"
								control={<Radio />}
								label="Выдвижение"
							/>
							<FormControlLabel
								value="subvote"
								control={<Radio />}
								label="Голосование"
							/>
							<FormControlLabel
								value="final"
								control={<Radio disabled={!state?.gameData?.final} />}
								label="Финал"
							/>
						</RadioGroup>
					</FormControl>
				</Paper>

				<Paper className="Admin-block Admin-c2" variant="outlined">
					<Typography variant="subtitle1">Функции</Typography>
					<Stack spacing={1} direction="column" style={{ marginTop: 8 }}>
						<Button variant="outlined" onClick={handleNewCards}>
							Перераздать карты
						</Button>
						<Button variant="outlined" onClick={handleOpenFinal}>
							Показать финал
						</Button>
						<Button variant="outlined" onClick={handleOpenTimer}>
							Запустить таймер
						</Button>
						<Button variant="outlined" onClick={handleHideModal}>
							Скрыть уведомление
						</Button>
						<Button variant="outlined" onClick={handleHideFullModal}>
							Скрыть б. уведомление
						</Button>
						{/* <Button variant="outlined">Перемешать игроков</Button> */}
					</Stack>
				</Paper>

				<Paper className="Admin-block Admin-c3" variant="outlined">
					<Typography variant="subtitle1">Видео</Typography>
					<Stack spacing={1} direction="column" style={{ marginTop: 8 }}>
						<Button variant="outlined" onClick={handleResetVideo}>
							С начала
						</Button>
						<Button variant="outlined" onClick={() => handlePlayChange(false)}>
							Стоп
						</Button>
						<Button variant="outlined" onClick={() => handlePlayChange(true)}>
							Старт
						</Button>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={!!state?.videoControl?.autoplay}
										onChange={handleAutoplayChange}
									/>
								}
								label="Автостарт"
							/>
						</FormGroup>
					</Stack>
				</Paper>

				<Paper className="Admin-block Admin-c4" variant="outlined">
					<Typography variant="subtitle1">Бункер и Катастрофа</Typography>
					<Stack
						spacing={1}
						direction="column"
						style={{ marginTop: 8, marginBottom: 8 }}
					>
						<Button variant="outlined" onClick={handleOpenBunker}>
							Показать бункер
						</Button>
						<Button variant="outlined" onClick={handleOpenCatastrophe}>
							Показать катастрофу
							<br />({state?.gameData?.catastrophe?.name})
						</Button>
					</Stack>
					<Typography variant="subtitle1">Управление</Typography>
					<Stack spacing={1} direction="column" style={{ marginTop: 8 }}>
						<Button variant="outlined" onClick={handleBunkerChange}>
							Сменить бункер
						</Button>
						<Button variant="outlined" onClick={handleCatastropheChange}>
							Сменить катастрофу
						</Button>
					</Stack>
				</Paper>

				{/* <Paper className="Admin-block Admin-catastrophe" variant="outlined">
					<Typography variant="subtitle1">
						{state?.gameData?.catastrophe?.name}
					</Typography>
					<Typography variant="body2">
						{state?.gameData?.catastrophe?.description}
					</Typography>
				</Paper> */}

				{/* <Paper className="Admin-block Admin-bunker" variant="outlined">
					<Typography variant="subtitle1">Бункер</Typography>
					<Typography
						variant="body2"
						dangerouslySetInnerHTML={{
							__html: state?.gameData?.bunker?.description,
						}}
					></Typography>
				</Paper> */}

				<TableContainer component={Paper} className="Admin-queue">
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Зритель (ID)</TableCell>
								<TableCell>Место</TableCell>
								<TableCell align="right"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{state?.queue &&
								Object.entries(state.queue.toJSON())
									.filter((row) => !state?.players.has(row[0]))
									.map((row) => (
										<TableRow
											key={row[0]}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<TableCell component="th" scope="row">
												{row[0]}
											</TableCell>
											<TableCell component="th" scope="row">
												{row[1].place}
											</TableCell>
											<TableCell align="right">
												<Stack direction="row-reverse" spacing={0}>
													<IconButton
														aria-label="delete"
														size="small"
														color="success"
														onClick={() => handleJoinPlayer(row[0])}
													>
														<PlayArrowIcon fontSize="inherit" />
													</IconButton>
													<IconButton
														aria-label="delete"
														size="small"
														color="error"
														onClick={() => handleKickQueuePlayer(row[0])}
													>
														<DeleteIcon fontSize="inherit" />
													</IconButton>
												</Stack>
											</TableCell>
										</TableRow>
									))}
						</TableBody>
					</Table>
				</TableContainer>

				<TableContainer component={Paper} className="Admin-gamers">
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Игрок (ID)</TableCell>
								<TableCell>№</TableCell>
								<TableCell>Жив</TableCell>
								<TableCell>Выдвинут</TableCell>
								<TableCell>Профессия</TableCell>
								<TableCell>Биология</TableCell>
								<TableCell>Здоровье</TableCell>
								<TableCell>Факт 1</TableCell>
								<TableCell>Багаж</TableCell>
								<TableCell>Хобби</TableCell>
								<TableCell>Факт 2</TableCell>
								<TableCell>Голос</TableCell>
								<TableCell align="right">Действие</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{state?.players &&
								Object.entries(state.players.toJSON())
									.sort((row1, row2) => row1[1].num - row2[1].num)
									.map((row) => {
										let pData = row[1];
										let vID = pData.num - 1;
										let vData = state.virtualPlayers[vID];
										return (
											<TableRow
												key={row[0]}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 },
												}}
											>
												<TableCell
													component="th"
													scope="row"
													style={{
														color: state.queue.has(row[0]) ? "inherit" : "red",
													}}
												>
													{row[0]}
												</TableCell>
												<TableCell>{pData.num}</TableCell>
												<TableCell>
													<Checkbox
														size="small"
														checked={vData.alive}
														onChange={(event) =>
															handleKillPlayer(vID, event.target.checked)
														}
													/>
												</TableCell>
												<TableCell>
													<Checkbox
														size="small"
														checked={vData.voted}
														onChange={(event) =>
															handleVotedPlayer(vID, event.target.checked)
														}
													/>
												</TableCell>
												{vData.cards.map((card, index) => (
													<TableCell>
														<FormControlLabel
															control={
																<Checkbox
																	// disabled={!vData.alive}
																	size="small"
																	checked={card.openned}
																	onChange={(event) =>
																		handleOpenCard(
																			vID,
																			index,
																			event.target.checked
																		)
																	}
																/>
															}
															label={card.text}
														/>
													</TableCell>
												))}
												<TableCell>
													{vData.vote === 0 ? "-" : vData.vote}
												</TableCell>
												<TableCell align="right">
													<Stack direction="row-reverse" spacing={0}>
														<IconButton
															aria-label="delete"
															size="small"
															color="error"
															onClick={() => handleKickPlayer(row[0])}
														>
															<DeleteIcon fontSize="inherit" />
														</IconButton>
														{vData.waitForOpen ? (
															<IconButton
																aria-label="delete"
																size="small"
																color="info"
																onClick={() => handleWaitCard(vID, false)}
															>
																<StopIcon fontSize="inherit" />
															</IconButton>
														) : (
															<IconButton
																aria-label="delete"
																size="small"
																color="info"
																onClick={() => handleWaitCard(vID, true)}
															>
																<PlayArrowIcon fontSize="inherit" />
															</IconButton>
														)}
														{/* <IconButton
															aria-label="delete"
															size="small"
															onClick={() => handleMoveBot(row[0])}
															// color="error"
														>
															<ArrowUpwardIcon fontSize="inherit" />
														</IconButton>
														<IconButton
															aria-label="delete"
															size="small"
															onClick={() => handleMoveTop(row[0])}
															// color="error"
														>
															<ArrowDownwardIcon fontSize="inherit" />
														</IconButton> */}
													</Stack>
												</TableCell>
											</TableRow>
										);
									})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</div>
	);
}

export default Admin;
