import { useEffect, useReducer, useRef, useState } from "react";
import * as Colyseus from "colyseus.js";
import { useParams } from "react-router-dom";
import "./View.css";
import { Button, Paper, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import { declOfNum } from "../../helpers/misc";

function View() {
	let { gameID } = useParams();
	const [client, setClient] = useState(null);
	const [room, setRoom] = useState(null);
	const [state, setState] = useState(null);
	const [error, setError] = useState("");
	const forceUpdate = useReducer(() => ({}))[1];
	const videoplayer = useRef(null);
	const [modal, setModal] = useState(null);
	const [modalFull, setModalFull] = useState(false);

	const CARDS_HEADERS = [
		"ПРОФЕССИЯ",
		"БИОЛОГИЯ",
		"ЗДОРОВЬЕ",
		"ФАКТЫ",
		"БАГАЖ",
		"ХОББИ",
	];

	useEffect(() => {
		setClient(new Colyseus.Client("wss://bunker.ugscompany.ru:2567"));
	}, []);

	useEffect(() => {
		const joinToView = async () => {
			try {
				const newRoom = await client.joinById(gameID, {
					admin: true,
				});
				console.log("joined successfully", newRoom);
				window.gRoom = newRoom;
				setRoom(newRoom);
				setError("");
			} catch (e) {
				console.error("join error", e);
				setError("Ошибка при входе в игру");
			}
		};
		if (client) joinToView();
	}, [client, gameID]);

	useEffect(() => {
		const setupListners = () => {
			room.onStateChange((lState) => {
				console.log(room.name, "has new state:", lState);
				setState(lState);
				window.gState = lState;
				forceUpdate();
			});

			room.onMessage("reset_video", (message) => {
				console.log("message 'reset_video' received from server", message);
				videoplayer?.current?.seekTo(0);
			});

			room.onMessage("hide_openned_card", (message) => {
				console.log(
					"message 'hide_openned_card' received from server",
					message
				);
				setModal(null);
			});

			room.onMessage("hide_openned_card_full", (message) => {
				console.log(
					"message 'hide_openned_card_full' received from server",
					message
				);
				// if (modalFull) {
				setModalFull(false);
				forceUpdate();
				// }
			});

			// room.onMessage("start_timer", (message) => {
			// 	console.log("message 'start_timer' received from server", message);
			// 	setSeconds(message.timer);
			// 	setTimerStarted(true);
			// 	let newTimer = setInterval(() => {
			// 		console.log(seconds);
			// 		if (seconds > 0) {
			// 			setSeconds(seconds - 1);
			// 		} else if (seconds <= 0) {
			// 			if (timer) clearInterval(timer);
			// 			setTimerStarted(false);
			// 		}
			// 	}, 1000);
			// 	setTimer(newTimer);
			// });

			// room.onMessage("stop_timer", (message) => {
			// 	if (timer) clearInterval(timer);
			// 	setTimerStarted(false);
			// });

			room.onMessage("show_openned_card", (message) => {
				console.log(
					"message 'show_openned_card' received from server",
					message
				);
				setModal({
					fullCard: message.card,
					card: message.card === 6 ? 3 : message.card,
					num: message.num,
				});
				setModalFull(message.card === 6);
				forceUpdate();
			});
		};
		if (room) setupListners();
	}, [room]);

	return (
		<div
			className="View-main"
			style={{
				background: ["rules", "catastrophe", "final"].includes(
					state?.gameData?.view
				)
					? "#000"
					: "inherit",
			}}
		>
			{state?.timerStarted && (
				<Typography
					variant="h3"
					style={{
						position: "absolute",
						top: "0",
						right: "8px",
					}}
				>
					{state?.seconds}
				</Typography>
			)}
			{state?.gameData?.view === "logo" && (
				<div>
					<img className="View-img" alt="" src="/images/corner.png" />
					<Typography align="center" variant="h2">
						IZBA CLUB
					</Typography>
					<div style={{ marginTop: "43vh", transform: "translateY(-50%)" }}>
						<Typography align="center" variant="h1">
							ТВОЙ ШАНС
						</Typography>
					</div>
				</div>
			)}
			{state?.gameData?.view === "rules" && (
				<ReactPlayer
					ref={videoplayer}
					className="View-video"
					url={state?.videoControl?.video}
					playing={state?.videoControl?.play}
					loop={false}
					muted={false}
					width="100vw"
					height="100vh"
				/>
			)}
			{state?.gameData?.view === "catastrophe" && (
				<ReactPlayer
					ref={videoplayer}
					className="View-video"
					url={state?.videoControl?.video}
					playing={state?.videoControl?.play}
					loop={false}
					muted={false}
					width="100vw"
					height="100vh"
				/>
			)}
			{state?.gameData?.view === "game" &&
				((modal && (
					<>
						<Typography gutterBottom align="center" variant="h3">
							ИГРОК #{modal.num + 1} ОТКРЫЛ КАРТУ
						</Typography>
						{state?.timerStarted && (
							<Typography
								variant="h3"
								style={{
									position: "absolute",
									top: "0",
									right: "8px",
								}}
							>
								{state?.seconds}
							</Typography>
						)}
						{(modalFull && (
							<div style={{ marginTop: "45vh", transform: "translateY(-50%)" }}>
								<Typography align="center" variant="h1">
									{state?.virtualPlayers[modal.num]?.cards[modal.fullCard]
										?.openned
										? state?.virtualPlayers[modal.num]?.cards[modal.fullCard]
												?.text
										: "???"}
								</Typography>
							</div>
						)) || (
							<div className="View-othercards">
								{state?.virtualPlayers[modal.num]?.cards.map((card, index) => {
									if (index < 6) {
										if (index === 3) {
											let secondfact = state.virtualPlayers[modal.num].cards[6];
											return (
												<Paper
													className="View-block"
													variant="outlined"
													style={{
														background:
															index === modal.card
																? "#005aff78"
																: card.openned || secondfact.openned
																? "#66bb6a7a"
																: "",
													}}
												>
													<Typography align="center" variant="h5">
														{CARDS_HEADERS[index]}
													</Typography>
													<Typography align="center" variant="h4">
														{card.openned ? card.text : "???"}
														{secondfact.openned ? ` / ${secondfact.text}` : ""}
													</Typography>
												</Paper>
											);
										} else
											return (
												<Paper
													className="View-block"
													variant="outlined"
													style={{
														background:
															index === modal.card
																? "#005aff78"
																: card.openned
																? "#66bb6a7a"
																: "",
													}}
												>
													<Typography align="center" variant="h5">
														{CARDS_HEADERS[index]}
													</Typography>
													<Typography align="center" variant="h4">
														{card.openned ? card.text : "???"}
													</Typography>
												</Paper>
											);
									} else return <></>;
								})}
							</div>
						)}
					</>
				)) || (
					<>
						<div className="View-allcards">
							{state?.players &&
								Object.entries(state.players.toJSON())
									.sort((row1, row2) => row1[1].num - row2[1].num)
									.map((row) => {
										let pData = row[1];
										let vID = pData.num - 1;
										let vData = state.virtualPlayers[vID];
										return (
											<Paper
												className="View-block"
												variant="outlined"
												style={{ color: vData.alive ? "" : "#6e6e6e" }}
											>
												<Typography align="center" variant="h3" gutterBottom>
													ИГРОК #{pData.num}
												</Typography>
												{vData.cards.map((card, index) => {
													if (index < 6) {
														if (index === 3) {
															let secondfact = vData.cards[6];
															return (
																<Typography variant="body2">
																	* {card.openned ? card.text : "???"}
																	{secondfact.openned
																		? ` / ${secondfact.text}`
																		: ""}
																</Typography>
															);
														} else
															return (
																<Typography variant="body2">
																	* {card.openned ? card.text : "???"}
																</Typography>
															);
													} else return <></>;
												})}
											</Paper>
										);
									})}
						</div>
					</>
				))}
			{state?.gameData?.view === "vote" && (
				<div className="View-votecards">
					{state?.players &&
						Object.entries(state.players.toJSON())
							.sort((row1, row2) => row1[1].num - row2[1].num)
							.map((row) => {
								let pData = row[1];
								let vID = pData.num - 1;
								let vData = state.virtualPlayers[vID];
								let votesCount = 0;
								for (let vp of state.virtualPlayers)
									if (vp.vote === pData.num) votesCount++;
								return (
									<div className="View-block">
										<Paper
											className="View-innerblock"
											variant="outlined"
											style={{ color: vData.alive ? "" : "#6e6e6e" }}
										>
											<Typography align="center" variant="h3" gutterBottom>
												ИГРОК #{pData.num}
											</Typography>
											{vData.cards.map((card, index) => {
												if (index < 6) {
													if (index === 3) {
														let secondfact = vData.cards[6];
														return (
															<Typography variant="body2">
																* {card.openned ? card.text : "???"}
																{secondfact.openned
																	? ` / ${secondfact.text}`
																	: ""}
															</Typography>
														);
													} else
														return (
															<Typography variant="body2">
																* {card.openned ? card.text : "???"}
															</Typography>
														);
												} else return <></>;
											})}
										</Paper>
										{vData.alive && votesCount > 0 && (
											<Button variant="outlined" color="error">
												ВЫДВИНУТ
											</Button>
										)}
									</div>
								);
							})}
				</div>
			)}
			{state?.gameData?.view === "subvote" && (
				<div className="View-votecards">
					{state?.players &&
						Object.entries(state.players.toJSON())
							.sort((row1, row2) => row1[1].num - row2[1].num)
							.map((row) => {
								let pData = row[1];
								let vID = pData.num - 1;
								let vData = state.virtualPlayers[vID];
								let votesCount = 0;
								for (let vp of state.virtualPlayers)
									if (vp.vote === pData.num) votesCount++;
								return (
									<div className="View-block">
										<Paper
											className="View-innerblock"
											variant="outlined"
											style={{ color: vData.alive ? "" : "#6e6e6e" }}
										>
											<Typography align="center" variant="h3" gutterBottom>
												ИГРОК #{pData.num}
											</Typography>
											{vData.cards.map((card, index) => {
												if (index < 6) {
													if (index === 3) {
														let secondfact = vData.cards[6];
														return (
															<Typography variant="body2">
																* {card.openned ? card.text : "???"}
																{secondfact.openned
																	? ` / ${secondfact.text}`
																	: ""}
															</Typography>
														);
													} else
														return (
															<Typography variant="body2">
																* {card.openned ? card.text : "???"}
															</Typography>
														);
												} else return <></>;
											})}
										</Paper>
										{vData.alive && vData.voted && (
											<Button variant="outlined" color="info">
												{votesCount === 0
													? "НЕТ ГОЛОСОВ"
													: `${votesCount} ${declOfNum(votesCount, [
															"ГОЛОС",
															"ГОЛОСА",
															"ГОЛОСОВ",
													  ])}`}
											</Button>
										)}
									</div>
								);
							})}
				</div>
			)}
			{state?.gameData?.view === "final" && (
				<ReactPlayer
					ref={videoplayer}
					className="View-video"
					url={state?.videoControl?.video}
					playing={state?.videoControl?.play}
					loop={false}
					muted={false}
					width="100vw"
					height="100vh"
				/>
			)}
		</div>
	);
}

export default View;
